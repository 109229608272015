import React, { Component } from 'react';
// import { Dropdown } from 'react-bootstrap';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        sidebarOnly: false
    };
}
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  handleLogout() {
    localStorage.removeItem('auth');
    window.open('/admin/login', '_self');
  }

  render() {
    return (
      <nav className='navbar col-12 p-lg-0 fixed-top d-flex flex-row'>
        <div className='navbar-menu-wrapper kikoadmin-header d-flex align-items-center justify-content-betweenn kiko-navbar'>
          <a
            className='navbar-brand brand-logo-mini align-self-center d-lg-none'
            href='!#'
            onClick={(e) => e.preventDefault()}
          >
            <img
              src={require('../../assets/images/logo-mini.svg')}
              alt='logo'
            />
          </a>
          <button
            className='navbar-toggler navbar-toggler align-self-center navbar_menu'
            type='button'
            onClick={() => {document.body.classList.toggle('sidebar-icon-only');this.setState(prevState => ({
              sidebarOnly: !prevState.sidebarOnly
          }));}}
          >
            {/* <i className='mdi mdi-chevron-left nav_icon'></i> */}
            <i className={this.state.sidebarOnly ? "mdi mdi-chevron-right nav_icon" : "mdi mdi-chevron-left nav_icon"}></i>
            
          </button>
          <ul className='navbar-nav navbar-nav-right'>
          <button
            className='navbar-toggler navbar-toggler align-self-center navbar_menu'
            type='button'
            onClick={() => this.handleLogout()}
          ><h5 style={{"color":"white"}}>Logout</h5>
          </button>
            {/* <li className='nav-item  nav-profile border-0 '>
              <Dropdown>
                <Dropdown.Toggle className='nav-link count-indicator bg-transparent nav_icon'>
                </Dropdown.Toggle>
                <Dropdown.Menu className='preview-list navbar-dropdown pb-3'>
                  <Dropdown.Item
                    className='dropdown-item preview-item d-flex align-items-center border-0 mt-2'
                    onClick={(e) => e.preventDefault()}
                  >
                    My Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='dropdown-item preview-item d-flex align-items-center border-0'
                    onClick={(e) => e.preventDefault()}
                  >
                    Admin Setting
                  </Dropdown.Item>
                  <Dropdown.Item
                    className='dropdown-item preview-item d-flex align-items-center border-0'
                    onClick={() => this.handleLogout()}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
          </ul>
          <button
            className='navbar-toggler navbar-toggler-right d-lg-none align-self-center'
            type='button'
            onClick={this.toggleOffcanvas}
          >
            <span className='mdi mdi-menu'></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
