import React, { Fragment } from 'react'
import { toast } from 'react-toastify';
import { get } from 'lodash'
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from 'antd'
import { CSVLink } from 'react-csv'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const notify = (type, message) => {
  toast[type](message, {
    position: toast.POSITION.BOTTOM_RIGHT,
  })
}

export const handleMultiFilter = selectedOptions => {
  return selectedOptions
    ? selectedOptions.reduce((accumulator, currentValue) => {
      return [...accumulator, currentValue.value]
    }, [])
    : []
}

export const nomenclature = (order) => {
  const status = order
  switch (status) {
    case "order_created":
      return "Order Created"
    case "looking_for_courier":
      return "Looking For Courier"
    case "courier_assigned":
      return "Courier Assigned"
    case "courier_departed":
      return "Courier Departed"
    case "parcel_picked_up":
      return "Parcel Picked Up"
    case "active":
      return "Active"
    case "inactive":
      return "In-Active"
    case "pending":
      return "Pending"
    case "order-pickup":
      return "Order Pickup"
    case "order-delivered":
      return "Order Delivered"
    case "courier_arrived":
      return "Courier Arrived"
    case "parcel_delivered":
      return "Parcel Delivered"
    case "order_deleted":
      return "Order Deleted"
    case "order_cancelled":
      return "Order Cancelled"
    case "order-cancelled":
      return "Order Cancelled"
    case "delayed":
      return "Delayed"
    case "order_failed":
      return "Order Failed"
    case "runner_cancelled":
      return "Runner Cancelled"
    case "payment-completed":
      return "Payment Completed"
    case "order-ready-to-pickup":
      return "Order Ready To Pickup"
    case "order-place":
      return "Order Place"
    case "drafted":
      return "Drafted"
    case "manual":
      return "Manual"
    case "dunzo":
      return "Dunzo"
    case "borzo":
      return "Borzo"
    case "paid":
      return "Paid"
    case "payment-failed":
      return "Payment Failed"
    case "planned":
      return "Planned"
    case "In transit":
      return "In Transit"
    case "order-dispatch":
      return "Order Dispatch"
    case "agent":
      return "Agent"
    case "callcenter":
      return "Call Center"
    case "influencer":
      return "Influencer"
    case "influence":
      return "Influencer"
    case "marketinguser":
      return "Marketing User"
    case "sadmin":
      return "Super Admin"
    case "semivendor":
      return "SELLER"
    case "store_owner":
      return "Store Owner"
    case "superadmin":
      return "super Admin"
    case "admin":
      return "Admin"
    case "user":
      return "BUYER"
    case "vendor":
      return "VERIFIED SELLER"
    case "deliverypartner":
      return "Delivery Partner"
    case "kikouser":
      return "Kiko User"
    case "orderexpired":
      return "Order Expired"
    case "rejected":
      return "Rejected"
    case "india":
      return "India"
    case "IND":
      return "India"
    case "India":
      return "India"
    case "seller":
      return "Seller"
    case "buyer":
      return "Buyer"
    case "completed":
      return "Completed"
    case "SelfDelivery":
      return "Self Delivery"
    case "Return_Approved":
      return "Return Approved"
    case "Return_Picked":
      return "Return Picked"
    case "Return_Delivered":
      return "Return Delivered"
    case "Liquidated":
      return "Liquidated"
    case "Return_Rejected":
      return "Return Rejected"
    case "Pending":
      return "Pending"
    case "Return_Initiated":
      return "Return Initiated"
    default:
      return ""
  }
}

export const LimitDropdown = ({ changeLimit, limit }) => {
  return (
    <div className='drop_down'>
      <label></label>
      <select value={limit} className='select-style drop_down_btn' onChange={(e) => { changeLimit(e.target.value) }} >
        <option value={20}>20</option>
        <option value={40}>40</option>
        <option value={60}>60</option>
        <option value={100}>100</option>
        {/* <option value={10000}>10000</option>
        <option value={100000}>100000</option> */}
        <option value={'NaN'}>All</option>
      </select>
    </div>
  )
}

export const BulkLimitDropdown = ({ changeLimit, limit }) => {
  return (
    <div className='drop_down'>
      <label></label>
      <select value={limit} className='select-style drop_down_btn' onChange={(e) => { changeLimit(e.target.value) }} >
        <option value={100}>100</option>
        <option value={500}>500</option>
        <option value={1000}>1000</option>
        <option value={2000}>2000</option>
        {/* <option value={10000}>10000</option>
        <option value={100000}>100000</option> */}
        <option value={'NaN'}>All</option>
      </select>
    </div>
  )
}

export const Categories = ({ changeCategory, categories, mainCategory }) => {
  return (
    <li className='form_options'>
      <label className="search_title">Category</label>
      <select className="select_style"
        value={mainCategory}
        onChange={(e) => { changeCategory(e.target.value) }}>
        <option value="">All</option>
        {categories.map((category, index) => {
          return (
            <option key={index} value={category.title}>{category.title}</option>
          )
        })}
      </select>
    </li>
  )
}
export const DataNotFound = () => {
  return (
    <div className='not-found'>
      <h4>Data not found</h4>
    </div>)
}
export const PaginationFilter = ({ onPageChanged, limit, count, page }) => {
  return (
    <Pagination
      className="float-left"
      hideOnSinglePage
      onChange={onPageChanged}
      current={page}
      pageSize={!limit ? count : limit}
      defaultCurrent={page}
      total={count}
      showSizeChanger={false}
    />
  )
}

export const CsvGenerator = ({ data, headings, exportLoading, onClick, fileName, buttonName }) => {
  return (
    <div className='float-right mr-2'>
      <CSVLink
        className='btn btn-primary btn-icon-text export_btn'
        data={data}
        headers={headings}
        filename={fileName}
        onClick={onClick}
      >
        {exportLoading ? 'Loading...' : buttonName}
      </CSVLink>
    </div>
  )
}

export const  GenerateExcel = async (data,fileName) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };

  saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${fileName}.xlsx`);

};

export const handleError = error => {
  if (error?.response?.data?.message === 'Unauthorized') {
    localStorage.removeItem('auth');
    window.open('/admin/login', '_self');
  }
  if (error?.response && error?.response?.data) {
    if (error?.response?.data?.error) {
      const err = get(error, 'response.data.error', '')
      if (
        get(err, 'statusCode', '') === 401 &&
        get(err, 'error', '') === 'Unauthorized'
      )
        return
      toast(
        ({ closeToast }) =>
          typeof err === 'string'
            ? err.includes('not a valid phone number') ||
              err.includes('is not an SMS enabled phone number')
              ? 'That phone number is not recognized'
              : err
            : Object.keys(err).map((element, index) => (
              <Fragment key={index}>
                {err[element]}
                <br />
              </Fragment>
            )),
        {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      )
    } else {
      notify('error', error?.response?.data?.message ?? "")
    }
  } else if (error?.message) {
    notify(
      'error',
      error?.message === 'Network Error'
        ? 'You are offline, please check your internet connection'
        : error?.message ?? ""
    )
  } else {
    notify('error', 'Something went wrong!')
  }
}
